<template>
  <v-card-text class="pb-0">
    <v-alert v-if="status && status != 200" class="mb-5"
      type="warning"
      color="#FFB74D"
    >
      {{ error }}
    </v-alert>
    <v-text-field density="comfortable" variant="outlined" v-model="email" :label="`${$t('email')}`" prepend-icon="mdi-email" required />
    <v-text-field density="comfortable" variant="outlined" v-model="password" :type="showPassword?'text':'password'" :label="`${$t('password')}`" prepend-icon="mdi-lock" :append-inner-icon="showPassword?'mdi-eye':'mdi-eye-off'" @click:append-inner="showPassword=!showPassword"/>
    <v-btn class="ml-9" text-color="white"  color="#4DB6AC" @click="login">{{ $t("login") }}</v-btn>
  </v-card-text>
</template>

<script>
export default {
  name: "LoginUser",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      status: null,
      error: null
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ path: `/${this.$i18n.locale}/events` });
        })
        .catch((err) => {
          this.status = err.response.status;
          this.error = err.response.data.message;
        });
    },
  },
};
</script>
