import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Home from "../views/Home.vue";
import Authenticate from "../views/Authenticate.vue";
import Events from "../views/Events.vue";
import CreateEvent from "../views/CreateEvent.vue";
import Contacts from "../views/Contacts.vue";
import AddContact from "../views/AddContact.vue";
import BulkAddContact from "../views/BulkAddContact.vue";
import ReactInvitation from "../views/ReactInvitation.vue";
import ReactEvent from "../views/ReactEvent.vue";
import ReactContact from "../views/ReactContact.vue";

/* import EventLayout from '../views/event/Layout.vue'
import EventDetails from '../views/event/Details.vue'
import EventRegister from '../views/event/Register.vue'
import EventEdit from '../views/event/Edit.vue' */

import { h, resolveComponent } from "vue";
import i18n from "../i18n";

const routes = [
  {
    path: '/',
    redirect: `/${i18n.global.locale}`
  },
  {
    path: "/:lang",
    component: {
      render() { return h(resolveComponent("router-view")); }
    },
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "authenticate",
        name: "authenticate",
        component: Authenticate,
      },
      {
        path: "events",
        name: "events",
        component: Events,
      },
      {
        path: "events/create",
        name: "createEvent",
        component: CreateEvent,
      },
      {
        path: "contacts",
        name: "contacts",
        component: Contacts,
      },
      {
        path: "contacts/add",
        name: "addContact",
        component: AddContact,
      },
      {
        path: "contacts/bulk",
        name: "bulkAddContact",
        component: BulkAddContact,
      },
      {
        path: "invitations/:id/:action",
        name: "reactInvitation",
        props: true,
        component: ReactInvitation,
      },
      /* {
        path: 'event/:id',
        name: 'EventLayout',
        props: true,
        component: EventLayout,
        children: [
          {
            path: '',
            name: 'EventDetails',
            component: EventDetails
          },
          {
            path: '/event/:id/register',
            name: 'EventRegister',
            component: EventRegister
          },
          {
            path: '/event/:id/edit',
            name: 'EventEdit',
            component: EventEdit
          }
        ]
      }, */
      {
        path: "event/:id/:action",
        name: "reactEvent",
        props: true,
        component: ReactEvent,
      },
      {
        path: "contact/:id/:action",
        name: "reactContact",
        props: true,
        component: ReactContact,
      },
      {
        path: "about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
    ]
  }

  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let language = to.params.lang;

  if (!language) {
    language = 'en'
  }

  i18n.global.locale = language;

  // redirect to login page if user is not logged in and trying to access a restricted page
  const publicPages = ["/", "/en", "/cht", "/en/authenticate", "/cht/authenticate"];
  
  const publicPagesStar = ["invitations"];

  const authRequired = !(publicPages.includes(to.path) || new RegExp(publicPagesStar.join("|")).test(to.path));
  const loggedIn = localStorage.getItem("user");

  // console.log(to.path);

  if (authRequired && !loggedIn) {
    // console.log('redirect');
    return next(`/${i18n.global.locale}/authenticate`);
  } 

  next();
});

export default router;
