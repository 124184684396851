<template>
  <v-card-text>
    <v-form>
      <v-alert v-if="status && status != 200" class="mb-5"
        type="warning"
        color="#FFB74D"
      >
        {{ error }}
      </v-alert>
      <vue-csv-import
          v-model="csv"
          :text="text"
          :fields="{name: {required: true, label: $t('name_')}, last_name: {required: true, label: $t('last_name')}, email: {required: true, label: $t('email_')}, phone: {required: true, label: $t('phone')}, category: {required: true, label: $t('category_')}}"
      >
          <div><vue-csv-toggle-headers></vue-csv-toggle-headers></div>
          <div><vue-csv-errors></vue-csv-errors></div>
          <div><vue-csv-input></vue-csv-input></div>
          <div v-if="!csv.length" class="v-table v-theme--light v-table--density-default">
            <div class="v-table__wrapper my-table">
              <vue-csv-map class="my-select"></vue-csv-map>
            </div>
          </div>
      </vue-csv-import>

      <div v-if="csv.length">
        <v-chip
          class="mt-5"
          label
        >
          {{$t('preview')}}
        </v-chip>

        <v-btn class="mt-5 ml-5" text-color="white"  color="pink" @click="$router.go(0)">{{ $t("reset") }}</v-btn>

        <v-table >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t("name")}}
                </th>
                <th class="text-left">
                  {{$t("last_name")}}
                </th>
                <th class="text-left">
                  {{$t("email")}}
                </th>
                <th class="text-left">
                  {{$t("phone")}}
                </th>
                <th class="text-left">
                  {{$t("category")}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="contact in csv"
                :key="contact.name"
              >
                <td>{{ contact.name }}</td>
                <td>{{ contact.last_name }}</td>
                <td>{{ contact.email }}</td>
                <td>{{ contact.phone }}</td>
                <td>{{ contact.category || $t('OTHER') }}</td>
              </tr>
            </tbody>
          </template>
        </v-table>

        
      </div>
      
      <div class="mt-3">
        <v-btn text-color="white" color="#4DB6AC" @click="bulk">{{ $t("bulk") }}</v-btn>
        <v-btn class="ml-3" @click="$router.go(-1)">{{ $t("back") }}</v-btn>
      </div>
      
    </v-form>
  </v-card-text>
  
</template>

<script>
export default {
  data () {
    return {
      status: null,
      error: null,
      csv: [],
      text: {
          errors: {
              fileRequired: 'A file is required',
              invalidMimeType: 'Invalid file type'
          },
          toggleHeaders: 'File has headers?',
          submitBtn: 'Submit',
          fieldColumn: 'Field',
          csvColumn: 'Column'
      },
    }
  },
  methods: {
    bulk () {
      this.$store
        .dispatch('bulk', this.csv)
        .then((/* { data } */) => { 
          this.$router.push({ path: "../contacts" })
        })
        .catch(err => { 
          this.status = err.response.status;
          this.error = err.response.data.message;
        })
    }
  }
}
</script>

<style>
  .my-select select {
    border-style: solid;
    -webkit-appearance: menulist;
  }
  .my-table {
    max-width: 500px;
  }
</style>