<template>
  <v-card class="mx-auto elevation-2" max-width="600">
    <v-card-title style="color: white; background-color:#4DB6AC;" class="text-overline">
      <h3>{{$t('edit')}} - {{ event.name }}</h3>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="status && status != 200" class="mb-5"
        type="warning"
        color="#FFB74D"
      >
        {{ error }}
      </v-alert>

      <Datepicker format="yyyy-MM-dd HH:mm" class="mb-8" v-model="event.commence"></Datepicker>
      <v-text-field density="comfortable" variant="outlined" v-model="event.name" :label="$t('event_name')" prepend-icon="mdi-calendar" required />
      <v-text-field density="comfortable" variant="outlined" v-model="event.venue" :label="$t('venue')" prepend-icon="mdi-map-marker-outline" required />
      <v-textarea density="comfortable" variant="outlined" v-model="event.address" :label="$t('address')" prepend-icon="mdi-google-maps" required />
      <!--<v-text-field v-model="event.commence" :label="`${$t('time')} (YYYY-MM-DD hh:mm:ss)`" prepend-icon="mdi-clock-outline" required />-->
      <v-select
        density="comfortable"
        variant="outlined"
        v-model="event.category"
        :items="[
          { value: 'BIRTHDAY', title: $t('BIRTHDAY') },
          { value: 'WEDDING', title: $t('WEDDING') },
          { value: 'BUSINESS', title: $t('BUSINESS') },
          { value: 'OTHER', title: $t('OTHER') },
        ]"
        :label="$t('category')"
        prepend-icon="mdi-calendar-question"
      ></v-select>

      <v-btn class="ml-9" text-color="white"  color="#4DB6AC" @click="eventAction">{{ $t(this.action) }}</v-btn>
      <v-btn class="ml-3" @click="$router.go(-1)">{{ $t("back") }}</v-btn>

    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: { Datepicker },
  props: ['id', 'action'],
  data () {
    return {
      status: null,
      error: null
    }
  },
  computed: mapState({
    event: state => state.event
  }),
  created () {
    this.$store
      .dispatch('fetchEvent', this.id)
      .catch(err => {
        this.status = err.response.status;
        this.error = err.response.data.message; 
      })
  },
  methods: {
    eventAction () {
      this.$store
        .dispatch(this.action + 'Event', this.event)
        .then((/* { data } */) => { this.$router.push({ path: "../../events" }) })
        .catch(err => { 
          this.status = err.response.status;
          this.error = err.response.data.message; 
        })
    }
  }
}
</script>