<template>
  <v-card
    class="mx-auto ma-5 elevation-2 rounded-lg"
    max-width="800"
  >
    <v-card-header>
      <div class="text-overline mb-0">
        <v-chip
          class="my-0"
          color="teal-accent-4"
          variant="outlined"
        >
          {{ $t(event.status)}}
        </v-chip>
      </div>
      <v-spacer />
      <v-btn class="mx-2 mt-2" size="x-small" variant="outlined" icon color="green" @click="refreshEvents()">
        <v-icon size="small">mdi-refresh</v-icon>
        <v-tooltip
          activator="parent"
          anchor="top"
        >{{ $t("refresh_tip") }}</v-tooltip>
      </v-btn>
      <v-btn class="mx-2 mt-2" size="x-small" variant="outlined" icon color="cyan-accent-3" :to="`event/${event.id}/edit`">
        <v-icon size="small">mdi-pencil-outline</v-icon>
        <v-tooltip
          activator="parent"
          anchor="top"
        >{{ $t("edit_tip") }}</v-tooltip>
      </v-btn>
      <v-btn class="mx-2 mt-2" size="x-small" variant="outlined" icon color="pink" @click="deleteEvent(event.id)">
        <v-icon size="small">mdi-trash-can-outline</v-icon>
        <v-tooltip
          activator="parent"
          anchor="top"
        >{{ $t("delete_tip") }}</v-tooltip>
      </v-btn>
    </v-card-header>

    <v-card-title>
      <div>
        <div class="ml-3 text-caption">
          <h2>{{ event.name }}</h2>
        </div>
        <div class="text-caption ml-3 mt-2">
          <v-chip
            class="my-0"
            color="teal-accent-4"
            label
            text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{ $t(event.category) }} 
          </v-chip>
        </div>
        <h5 class="mt-3 ml-3">
          <v-icon color="pink">mdi-map-marker-outline</v-icon>
          {{ event.venue }}
        </h5>
        <h5 class="text-overline ml-12">
          {{ event.address }}
        </h5>
        <h5 class="mt-3 ml-3" >
          <v-icon color="teal-accent-4" size="small">mdi-clock-outline</v-icon>
          {{ parsedTime }}
        </h5>
        <h5 class="text-overline ml-12">
          on {{ parsedDate }}
        </h5>
        
        <!--<div class="text-caption"> {{ event.guests.length }} {{ $t("guests") }} </div>-->
      </div>
    </v-card-title>
    <v-card-actions>
      <v-btn
        variant="text"
        color="teal-accent-4"
        
        @click="revealGuests"
      >
        {{ event.guests.length }} {{ $t("guests") }}
      </v-btn>
      <v-btn
        variant="text"
        color="teal-accent-4"
        
        @click="revealContacts"
      >
        {{ $t("invite") }}
      </v-btn>
      <v-btn
        variant="text"
        color="teal-accent-4"
        
        @click="revealStats"
      >
        {{ $t("stats") }}
      </v-btn>
      <v-spacer />
      <v-btn
        variant="outlined"
        color="teal-accent-4"
        
        @click="confirm"
      >
        {{ $t("confirm") }}
        <v-tooltip
          activator="parent"
          anchor="top"
        >{{ $t("confirm_tip") }}</v-tooltip>
      </v-btn>
      <v-btn
        variant="outlined"
        color="teal-accent-4"
        
        @click="start"
      >
        {{ $t("start") }}
        <v-tooltip
          activator="parent"
          anchor="top"
        >{{ $t("start_tip") }}</v-tooltip>
      </v-btn>
      
    </v-card-actions>

    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        
      >
        <v-table class="rounded" v-if="listGuests">
          <template v-slot:default>
            <thead>
              <tr style="background-color: #4DB6AC; color: white;">
                <th class="text-left">
                  {{ $t("name") }}
                </th>
                <th class="text-left">
                  {{ $t("email") }}
                </th>
                <th class="text-left">
                  {{ $t("phone") }}
                </th>
                <th class="text-left">
                  {{ $t("category") }}
                </th>
                <th class="text-left">
                  {{ $t("status") }}
                </th>
                <th class="text-left">
                  {{ $t("actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invitation in event.invitations"
                :key="invitation.guest.id"
              >
                <td>{{ invitation.guest.name }}</td>
                <td>{{ invitation.guest.email }}</td>
                <td>{{ invitation.guest.phone }}</td>
                <td>{{ $t(invitation.guest.category) }}</td>
                <td>{{ $t(invitation.status) }}</td>
                <td>
                  <v-btn v-if="event.status == 'CONFIRMED'" class="ma-2" size="x-small" variant="outlined" icon color="green" @click="resend(invitation.id)">
                    <v-icon>mdi-email-sync-outline</v-icon>
                    <v-tooltip
                      activator="parent"
                      anchor="top"
                    >{{ $t("resend_tip") }}</v-tooltip>
                  </v-btn>
                  <v-btn class="ma-2" size="x-small" variant="outlined" icon color="cyan-accent-3" :to="`invitations/${invitation.id}/qrcode`">
                    <v-icon>mdi-qrcode</v-icon>
                    <v-tooltip
                      activator="parent"
                      anchor="top"
                    >{{ $t("qr_checkin_tip") }}</v-tooltip>
                  </v-btn>
                  <v-btn class="ma-2" size="x-small" variant="outlined" icon color="pink" @click="uninvite(invitation.id)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                    <v-tooltip
                      activator="parent"
                      anchor="top"
                    >{{ $t("delete_tip") }}</v-tooltip>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
        

        <v-table class="rounded" v-if="listContacts">
          <template v-slot:default>
            <thead>
              <tr style="background-color: #4DB6AC; color: white;">
                <th class="text-left">
                  {{ $t("name") }}
                </th>
                <th class="text-left">
                  {{ $t("email") }}
                </th>
                <th class="text-left">
                  {{ $t("phone") }}
                </th>
                <th class="text-left">
                  {{ $t("category") }}
                </th>
                <th class="text-left">
                  {{ $t("actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="contact in contacts"
                :key="contact.id"
              >
                <td>{{ contact.name }}</td>
                <td>{{ contact.email }}</td>
                <td>{{ contact.phone }}</td>
                <td>{{ $t(contact.category) }}</td>
                <td>
                  <v-btn v-if="!this.guests.includes(contact.id)" class="ma-2" size="x-small" variant="outlined" icon color="teal-accent-3" @click="invite(contact.id)">
                    <v-icon>mdi-account-plus-outline</v-icon>
                    <v-tooltip
                      activator="parent"
                      anchor="top"
                    >{{ $t("invite_tip") }}</v-tooltip>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>

        
        <v-row v-if="listStats">
          <v-col cols="12" sm="8">
            <apexchart class="ma-5" max-width="50%" type="pie" :options="chartOptions" :series="series"></apexchart>
          </v-col>
        </v-row>

        <v-divider />

        <v-card-actions class="pt-0">
          <v-btn
            variant="text"
            color="teal-accent-4"
            @click="reveal = listGuests = listContacts = listStats = false"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>

        


    </v-expand-transition>
  </v-card>
  <v-snackbar
      v-model="snackbar"
    >
      {{ $t(text) }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackbar = false"
        >
          {{ $t('close')}}
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "EventCard",
  data: () => ({
    reveal: false,
    listContacts: false,
    listGuests: false,
    listStats: false,
    status: null,
    // guests: []
    snackbar: false,
    text: 'invit_resend',

    /* series: [44, 55, 13, 43, 22],
    /chartOptions: {
      chart: {
      },
      labels: ['Invited', 'Accepted', 'Declined', 'Attended'],
      
    },*/
  }),
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  
  computed: mapState({
    contacts: state => state.contacts,
    parsedDate() {
      const eventDate = new Date(this.event.commence)
      return eventDate.toDateString()
    },
    parsedTime() {
      const eventDate = new Date(this.event.commence)
      return eventDate.toTimeString()
    },
    guests() {
      return this.event.invitations.map(a => a.guest.id);
    },
    series() {
      return [
        this.event.invitations.filter( i => i.status === 'TO_INVITE' ).length,
        this.event.invitations.filter( i => i.status === 'ACCEPTED' ).length,
        this.event.invitations.filter( i => i.status === 'ATTENDED' ).length,
        this.event.invitations.filter( i => i.status === 'DECLINED' ).length
      ]
    },
    chartOptions() {
      return {
        labels: [
          this.event.invitations.filter( i => i.status === 'TO_INVITE' ).length + ' Invited',
          this.event.invitations.filter( i => i.status === 'ACCEPTED' ).length + ' Accepted',
          this.event.invitations.filter( i => i.status === 'ATTENDED' ).length + ' Attended',
          this.event.invitations.filter( i => i.status === 'DECLINED' ).length + ' Declined'
        ]
      }
    },
    
  }),

  methods: {
    revealContacts() {
      this.listContacts = this.reveal = !this.listContacts;
      this.listGuests = this.listStats = false;
      this.$store
        .dispatch("fetchContacts")
        .catch((err) => {
          this.status = err.response.status;
        });
    },
    revealGuests() {
      this.listGuests = this.reveal = !this.listGuests;
      this.listContacts = this.listStats = false;
    },
    revealStats() {
      this.listStats = this.reveal = !this.listStats;
      this.listContacts = this.listGuests = false;
    },
    invite (contactId) {
      this.$store
        .dispatch('invite', {
          eventId: this.event.id,
          guest: contactId,
          invite_note: ''
        })
        .then((/* { data } */) => { 
          this.$store
            .dispatch("fetchEvents")
            .catch((err) => {
              this.status = err.response.status;
            });
        })
        .catch(err => { this.status = err.response.status });
      
      // this.guests.push(contactId);
    },
    confirm () {
      this.$store
        .dispatch('confirm', this.event.id)
        .then((/* { data } */) => { 
          this.$store
            .dispatch("fetchEvents")
            .catch((err) => {
              this.status = err.response.status;
            });
        })
        .catch(err => { this.status = err.response.status })
    },
    start () {
      this.$store
        .dispatch('start', this.event.id)
        .then((/* { data } */) => { 
          this.$store
            .dispatch("fetchEvents")
            .catch((err) => {
              this.status = err.response.status;
            });
        })
        .catch(err => { this.status = err.response.status })
    },
    deleteEvent (eventId) {
      this.$store
        .dispatch('delete', eventId)
        .then((/* { data } */) => { 
          this.$store
            .dispatch("fetchEvents")
            .catch((err) => {
              this.status = err.response.status;
            });
        })
        .catch(err => { this.status = err.response.status })
    },
    refreshEvents () {
      this.$store
        .dispatch("fetchEvents")
        .catch((err) => {
          this.status = err.response.status;
        });
    },
    uninvite (inviteId) {
      this.$store
        .dispatch('uninvite', inviteId)
        .then((/* { data } */) => { 
          this.$store
            .dispatch("fetchEvents")
            .catch((err) => {
              this.status = err.response.status;
            });
        })
        .catch(err => { this.status = err.response.status })
    }
    ,
    resend (inviteId) {
      this.$store
        .dispatch('resend', inviteId)
        .then((/* { data } */) => { 
          this.$store
            .dispatch("fetchEvents")
            .catch((err) => {
              this.status = err.response.status;
            });
          
          this.snackbar = true;
        })
        .catch(err => { this.status = err.response.status })
    }
  }
};
</script>



<!--<style scoped>
.event-card {
  width: 13em;
  margin: 1em auto 1em auto;
  padding: 1em;
  border: solid 1px #2c3e50;
  cursor: pointer;
  transition: all 0.2s linear;
}
.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 15px 0 rgba(0, 0, 0, 0.19);
}
.event-card h4 {
  font-size: 1.4em;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
}
</style>-->
