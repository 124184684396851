<template>
  <v-card class="mx-auto elevation-2 rounded-lg" max-width="400">
    <v-card-title style="color: white; background-color:#4DB6AC;" class="text-overline">
      <h3>{{$t('welcome')}}</h3>
    </v-card-title>
    <component :is="loginOrRegister" />
    <v-col class="pt-0 text-right">
      <a style="font-size: 10px;" v-show="isNewUser" @click="toggleComponent">
        {{ $t("create_acct") }}
      </a>
      <a style="font-size: 10px;" v-show="!isNewUser" @click="toggleComponent">
        {{ $t("login_acct") }}
      </a>
    </v-col>
  </v-card>
</template>

<script>
import RegisterUser from "../components/RegisterUser";
import LoginUser from "../components/LoginUser";

export default {
  title: 'Eventi - Login',
  components: { RegisterUser, LoginUser },
  computed: {
    isNewUser() {
      return this.$store.state.isNewUser;
    },
    loginOrRegister() {
      return this.isNewUser ? "LoginUser" : "RegisterUser";
    },
  },
  methods: {
    toggleComponent() {
      this.$store.dispatch("isNewUser", !this.isNewUser);
    },
  },
  created() {
    if (this.$store.state.user) {
      this.$router.push("events");
    }
  },
};
</script>

<!--<style lang="scss" scoped>
.auth-link {
  font-size: 0.8em;
  text-decoration: underline;
  color: #2c3e50;
  cursor: pointer;
}
</style>-->
