export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use the app, you'll need to"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Eventi"])},
  "create_acct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account? Create one."])},
  "login_acct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account? Login."])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Event"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Contact"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name *"])},
  "name_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email *"])},
  "email_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category *"])},
  "category_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address *"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time *"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Name *"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password *"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "bulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Add (CSV)"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
  "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "confirm_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the event is confirmed, invitation email will be sent to the invited guests"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "start_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only after the event is started, the guests checkin function will be activated"])},
  "invit_resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation resent!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "refresh_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "edit_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "delete_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "qr_checkin_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in QR"])},
  "resend_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend invitation email"])},
  "invite_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
  "PLANNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
  "CONFIRMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
  "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
  "TO_INVITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited"])},
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
  "DECLINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
  "ATTENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attended"])},
  "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
  "VIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP"])},
  "FRIEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend"])},
  "COLLEAGUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colleague"])},
  "CLASSMATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classmate"])},
  "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "BIRTHDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "WEDDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding"])},
  "BUSINESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])}
}