export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用此應用程序，您需要"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎來到Eventi"])},
  "create_acct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有帳戶？創建一個。"])},
  "login_acct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已經有一個帳戶？登錄。"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主頁"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的活動"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的聯絡"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建活動"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增聯絡"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登記"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "name_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電郵"])},
  "email_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電郵"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分類"])},
  "category_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分類"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動名"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地點"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增"])},
  "bulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量新增 (CSV)"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計"])},
  "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動確認"])},
  "confirm_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動一經確認，將向受邀者發送邀請郵件"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動開始"])},
  "start_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有活動開始後，才會開啟嘉賓簽到功能"])},
  "invit_resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已重新發送邀請!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀態"])},
  "refresh_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
  "edit_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
  "delete_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
  "qr_checkin_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簽到二維碼"])},
  "resend_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重發邀請郵件"])},
  "invite_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請"])},
  "PLANNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規劃中"])},
  "CONFIRMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已確認"])},
  "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開始"])},
  "TO_INVITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已邀請"])},
  "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
  "DECLINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒絕"])},
  "ATTENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已出席"])},
  "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家人"])},
  "VIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP"])},
  "FRIEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朋友"])},
  "COLLEAGUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同事"])},
  "CLASSMATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同學"])},
  "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "BIRTHDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
  "WEDDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["婚宴"])},
  "BUSINESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商業活動"])}
}