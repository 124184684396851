import "./plugins/axios";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import i18n from "./i18n";
import titleMixin from './mixins/titleMixin'
import {VueCsvImportPlugin} from "vue-csv-import";
import VueApexCharts from "vue3-apexcharts";
import VueGoodTablePlugin from 'vue-good-table-next';

import 'vue-good-table-next/dist/vue-good-table-next.css'

loadFonts();

createApp(App).use(i18n).use(router).use(store).use(vuetify).use(VueCsvImportPlugin).use(VueApexCharts).use(VueGoodTablePlugin).mixin(titleMixin).mount("#app");
