<template>
  
  <div>
    <v-btn variant="outlined" to="contacts/add">{{$t("add")}}</v-btn>
    <v-btn class="ml-2" variant="outlined" to="contacts/bulk">{{$t("bulk")}}</v-btn>
  </div>
  
  <div>
    <vue-good-table class="mt-3"
      :columns="columns"
      :rows="contacts"
      :search-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: true,
        multipleColumns: true,
        initialSortBy: [
          {field: 'name', type: 'asc'}
        ],
      }"
      theme="polar-bear"
      styleClass="vgt-table condensed"
    >
    
      <template #table-row="props">
        <span v-if="props.column.field == 'actions'">
          <v-btn class="ma-1" size="x-small" variant="outlined" icon color="cyan-accent-3" :to="`contact/${props.row.id}/edit`">
            <v-icon size="small">mdi-pencil-outline</v-icon>
            <v-tooltip
              activator="parent"
              anchor="top"
            >{{ $t("edit_tip") }}</v-tooltip>
          </v-btn>
          <v-btn class="ma-1" size="x-small" variant="outlined" icon color="pink" @click="remove(props.row.id)">
            <v-icon>mdi-trash-can-outline</v-icon>
            <v-tooltip
              activator="parent"
              anchor="top"
            >{{ $t("delete_tip") }}</v-tooltip>
          </v-btn>
        </span>
        <span v-else-if="props.column.field == 'category'">
          {{$t(props.formattedRow[props.column.field])}}
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
    
  <!--<v-table class="mt-4 rounded-lg">
    <template v-slot:default>
      <thead>
        <tr style="background-color: #4DB6AC; color: white;">
          <th class="text-left">
            {{$t("name")}}
          </th>
          <th class="text-left">
            {{$t("last_name")}}
          </th>
          <th class="text-left">
            {{$t("email")}}
          </th>
          <th class="text-left">
            {{$t("phone")}}
          </th>
          <th class="text-left">
            {{$t("category")}}
          </th>
          <th class="text-left">
            {{$t("actions")}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="contact in contacts"
          :key="contact.id"
        >
          <td>{{ contact.name }}</td>
          <td>{{ contact.last_name }}</td>
          <td>{{ contact.email }}</td>
          <td>{{ contact.phone }}</td>
          <td>{{ $t(contact.category) }}</td>
          <td>
            <v-btn class="ma-2" size="x-small" variant="outlined" icon color="cyan-accent-3" :to="`contact/${contact.id}/edit`">
              <v-icon size="small">mdi-pencil-outline</v-icon>
              <v-tooltip
                activator="parent"
                anchor="top"
              >{{ $t("edit_tip") }}</v-tooltip>
            </v-btn>
            <v-btn class="ma-2" size="x-small" variant="outlined" icon color="pink" @click="remove(contact.id)">
              <v-icon>mdi-trash-can-outline</v-icon>
              <v-tooltip
                activator="parent"
                anchor="top"
              >{{ $t("delete_tip") }}</v-tooltip>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-table>-->

</template>

<script>
import { mapState } from 'vuex'

export default {
  title: "Eventi - Contacts",
  data() {
    return { 
      status: null,
    };
  },
  computed: mapState({
    contacts: state => state.contacts,
    columns() {
      return( [
        {
          label: this.$t('name'),
          field: 'name',
        },
        {
          label: this.$t('last_name'),
          field: 'last_name',
        },
        {
          label: this.$t('email'),
          field: 'email',
        },
        {
          label: this.$t('phone'),
          field: 'phone',
        },
        {
          label: this.$t('category'),
          field: 'category',
        },
        {
          label: this.$t('actions'),
          field: 'actions',
        }
      ]);
    }
  }), 
  created() {
    this.$store
      .dispatch("fetchContacts")
      .catch((err) => {
        this.status = err.response.status;
      });
  },
  methods: {
    remove (contactId) {
      this.$store
        .dispatch('remove', contactId)
        .then((/* { data } */) => { 
          this.$store
            .dispatch("fetchContacts")
            .catch((err) => {
              this.status = err.response.status;
            });
        })
        .catch(err => { this.status = err.response.status })
    }
  }
};
</script>

<style>
  .vgt-wrap.polar-bear div.vgt-global-search {
    border-radius: 10px 10px 0 0;
  }
  table.vgt-table td.vgt-left-align {
    vertical-align: middle;
  }
  table.vgt-table.polar-bear thead tr th {
    background: #4DB6AC;
    color: white;
  }
  table.vgt-table.polar-bear thead th.sorting-asc, table.vgt-table.polar-bear thead th.sorting-desc {
    color: #409eff;
  }
</style>
