<template>
  <div class="text-center ma-2">
    <div v-if="qrcode" class="d-flex flex-column justify-space-between align-center">
      <v-img width="500" :src="qrcode"></v-img>
    </div>
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ['id', 'action'],
  data () {
    return {
      snackbar: false,
      text: `You have ${this.action}-ed the invitation`,
      qrcode: "",
      status: null
    }
  },
  created () {
    this.$store
      .dispatch('react', {
        id: this.id, 
        action: this.action
      })
      .then(({data}) => { 
        this.snackbar = true;
        if (data.qr_code) {
          this.qrcode = data.url;
          this.text = "QR Code for check-in generated";
        }
      })
      .catch(err => { this.status = err.response.status })
  }
}
</script>