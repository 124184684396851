<template>
  <v-card class="mx-auto elevation-2" max-width="600">
    <v-card-title style="color: white; background-color:#4DB6AC;" class="text-overline">
      <h3>{{$t('edit')}} - {{ contact.name }}</h3>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="status && status != 200" class="mb-5"
        type="warning"
        color="#FFB74D"
      >
        {{ error }}
      </v-alert>

      <v-text-field density="comfortable" variant="outlined" v-model="contact.name" :label="$t('name')" prepend-icon="mdi-account-circle" required />
      <v-text-field density="comfortable" variant="outlined" v-model="contact.last_name" :label="$t('last_name')" prepend-icon="mdi-account-group" />
      <v-text-field density="comfortable" variant="outlined" v-model="contact.email" :label="$t('email')" prepend-icon="mdi-email" required />
      <v-text-field density="comfortable" variant="outlined" v-model="contact.phone" :label="$t('phone')" prepend-icon="mdi-phone" required />
      <v-select
        density="comfortable"
        variant="outlined"
        v-model="contact.category"
        :items="[
          { value: 'FAMILY', title: $t('FAMILY') },
          { value: 'VIP', title: $t('VIP') },
          { value: 'FRIEND', title: $t('FRIEND') },
          { value: 'COLLEAGUE', title: $t('COLLEAGUE') },
          { value: 'CLASSMATE', title: $t('CLASSMATE') },
          { value: 'OTHER', title: $t('OTHER') },
        ]"
        :label="$t('category')"
        prepend-icon="mdi-account-question"
      ></v-select>

      <v-btn class="ml-9" text-color="white"  color="#4DB6AC" @click="contactAction">{{ $t(this.action) }}</v-btn>
      <v-btn class="ml-3" @click="$router.go(-1)">{{ $t("back") }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['id', 'action'],
  data () {
    return {
      status: null,
      error: null
    }
  },
  computed: mapState({
    contact: state => state.contact
  }),
  created () {
    this.$store
      .dispatch('fetchContact', this.id)
      .catch(err => { 
        this.status = err.response.status;
        this.error = err.response.data.message;
      })
  },
  methods: {
    contactAction () {
      this.$store
        .dispatch(this.action + 'Contact', this.contact)
        .then((/* { data } */) => { this.$router.push({ path: "../../contacts" }) })
        .catch(err => { 
          this.status = err.response.status;
          this.error =err.response.data.message;
        })
    }
  }
}
</script>