<template>
  <v-row>
    <v-col class="text-center">
      <!--<h1>{{ $t("welcome") }}</h1>
        <p v-if="!this.$store.state.user">
          {{ $t("message") }}
          <router-link :to="`/${$i18n.locale}/authenticate`" @click="isNew(false)">
            {{ $t("login") }}
          </router-link>
          /
          <router-link :to="`/${$i18n.locale}/authenticate`" @click="isNew(true)">
            {{ $t("register") }}
          </router-link>
        </p>-->
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Eventi - Welcome",
  methods: {
    isNew(isNewUser) {
      this.$store.dispatch("isNewUser", !isNewUser);
    },
  },
  beforeCreate() {
    window.location.href = '/home/';
  },
};
</script>
