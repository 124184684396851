<template>
  <v-app>
    <!--<v-app-bar >
      <v-app-bar-title>
      <a :href="`/${$i18n.locale}`" style="text-decoration: none;"><span style="color: #485664; font-size: 32px; font-family: Poppins, sans-serif; font-weight: 300;">Eventi</span></a>
      <span style="color: #0ABAB5; font-size: 40px;">.</span>
      </v-app-bar-title>
      
      <v-btn class="pa-3"
        v-for="link in links"
        :key="`${link.label}-header-link`"
        :to="`/${$i18n.locale}${link.url}`"
      >
        {{ $t(link.label) }}
      </v-btn>
      <v-btn class="pa-3" v-if="user" to="/" @click="logout">
        {{ $t("logout") }}
      </v-btn>
      <v-btn class="pa-3" v-else :to="`/${$i18n.locale}/authenticate`">
        {{ $t("login") }}
      </v-btn>

      <v-chip
        class="ma-2"
        color="teal-accent-4"
        outlined
      >
        <a class="ma-1" @click="$i18n.locale='cht'">中</a> | <a class="ma-1" @click="$i18n.locale='en'">Eng</a>
      </v-chip>
    </v-app-bar>-->
        

        <v-navigation-drawer
          v-model="drawer"
          temporary
        >
          <v-list-item
          >
            <a><v-avatar
              color="#4DB6AC"
            >
              <!--<span class="white--text text-h5" style="color: white;">EL</span>-->
              <v-icon color="white" icon="mdi-account-circle"></v-icon>
            </v-avatar></a>
            <a :href="`/${$i18n.locale}`" style="text-decoration: none;" class="ml-5">
              <span style="color: #485664; font-size: 28px; font-family: Poppins, sans-serif; font-weight: 300;">Eventi</span>
              <span style="color: #0ABAB5; font-size: 30px;">.</span>
            </a>
          </v-list-item>

          <v-divider></v-divider>
          <v-list
            density="comfortable" nav
            class="px-2"
          >
            <v-list-item
              v-for="link in links"
              :key="`${link.label}-header-link`"
              :to="`/${$i18n.locale}${link.url}`"
              :prepend-icon="`${link.icon}`"
              color="#00000099"
            >
              <!--:title="$t(link.label)"-->
              <v-list-item-title v-if="link.icon==''" class="text-caption ml-3">{{ $t(link.label) }}</v-list-item-title>
              <v-list-item-title v-else class="text-overline">{{ $t(link.label) }}</v-list-item-title>
            </v-list-item>
            
          </v-list>
          <template v-slot:append>
            <v-divider></v-divider>
            <div class="pa-3">
              <v-btn text-color="white" rounded="pill" block color="#4DB6AC" v-if="user" to="/" @click="logout">{{ $t("logout") }}</v-btn>
              <v-btn text-color="white" rounded="pill" block color="#4DB6AC" v-else :to="`/${$i18n.locale}/authenticate`">{{ $t("login") }}</v-btn>
            </div>
          </template>
          
        </v-navigation-drawer>
        <v-app-bar density="comfortable" class="elevation-3">
          <template v-slot:prepend>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          </template>

          <v-toolbar-title>
            <a :href="`/${$i18n.locale}`" style="text-decoration: none;"><span style="color: #485664; font-size: 28px; font-family: Poppins, sans-serif; font-weight: 300;">Eventi</span></a>
            <span style="color: #0ABAB5; font-size: 30px;">.</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          
          <v-chip
            class="ma-2"
            color="teal-accent-4"
            variant="outlined"
          >
            <a class="ma-1" @click="$i18n.locale='cht'">中</a> | <a class="ma-1" @click="$i18n.locale='en'">Eng</a>
          </v-chip>
          <a><v-avatar
            color="#4DB6AC"
            class="ml-1"
          >
            <!--<span class="white--text text-h5" style="color: white;">EL</span>-->
            <v-icon color="white" icon="mdi-account-circle"></v-icon>
          </v-avatar></a>

          <!--<v-btn color="teal-accent-4" v-if="user" to="/" @click="logout">{{ $t("logout") }}</v-btn>
          <v-btn color="teal-accent-4" v-else :to="`/${$i18n.locale}/authenticate`">{{ $t("login") }}</v-btn>-->
          
        </v-app-bar>
        <v-main>
          <!--<v-container style="height: 100vh;" fluid>-->
          <v-container fluid>
            <router-view />
          </v-container>
        </v-main>

    
  </v-app>
</template>

<script>
import axios from "axios";
import AppNav from "./components/AppNav";
import { mapState } from 'vuex'

export default {
  name: "App",

  data: () => ({
    links: [
      /*{
        label: "home",
        url: ""
      },*/
      {
        label: "events",
        url: "/events",
        icon: "mdi-calendar"
      },
      {
        label: "create",
        url: "/events/create",
        icon: ""
      },
      {
        label: "contacts",
        url: "/contacts",
        icon: "mdi-account-circle"
      },
      {
        label: "add",
        url: "/contacts/add",
        icon: ""
      },
      {
        label: "bulk",
        url: "/contacts/bulk",
        icon: ""
      },
    ],
    drawer: false,
  }),
  computed: mapState({
    user: state => state.user
  }),
  methods: {
    logout () {
      this.$store.dispatch('logout');
    }
  },
  created() {
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USER_DATA", userData);
    }
    
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error.response);
        if (error.response.status === 401 && this.$route.name != 'authenticate') {
          this.$router.push("authenticate");
          this.$store.dispatch("logout");
        }
        return Promise.reject(error);
      }
    );
    
  },

  components: { AppNav },
};
</script>

<style>
  #app div {
    font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  #app div .v-btn {
    font-family: Poppins, sans-serif;
  }

  tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .02);
  }
</style>

<!--<style lang="scss">
@import "./assets/styles/global.scss";
.page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 56px);
}
</style>-->
