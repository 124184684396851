<template>
  <v-card class="mx-auto elevation-2 rounded-lg" max-width="600">
    <v-card-title style="color: white; background-color:#4DB6AC;" class="text-overline">
      <h3>{{$t('contact')}}</h3>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="status && status != 200" class="mb-5"
        type="warning"
        color="#FFB74D"
      >
        {{ error }}
      </v-alert>
      
      <v-text-field density="comfortable" variant="outlined" v-model="name" :label="$t('name')" prepend-icon="mdi-account-circle" required />
      <v-text-field density="comfortable" variant="outlined" v-model="last_name" :label="$t('last_name')" prepend-icon="mdi-account-group" />
      <v-text-field density="comfortable" variant="outlined" v-model="email" :label="$t('email')" prepend-icon="mdi-email" required />
      <v-text-field density="comfortable" variant="outlined" v-model="phone" :label="$t('phone')" prepend-icon="mdi-phone" required />
      <v-select
        density="comfortable"
        variant="outlined" 
        v-model="category"
        :items="[
          { value: 'FAMILY', title: $t('FAMILY') },
          { value: 'VIP', title: $t('VIP') },
          { value: 'FRIEND', title: $t('FRIEND') },
          { value: 'COLLEAGUE', title: $t('COLLEAGUE') },
          { value: 'CLASSMATE', title: $t('CLASSMATE') },
          { value: 'OTHER', title: $t('OTHER') },
        ]"
        :label="$t('category')"
        prepend-icon="mdi-account-question"
      ></v-select>
      
      <v-btn class="ml-9" text-color="white"  color="#4DB6AC" @click="add">{{ $t("add") }}</v-btn>
      <v-btn class="ml-3" @click="$router.go(-1)">{{ $t("back") }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      name: '',
      last_name: '',
      email: '',
      phone: '',
      category: 'OTHER',
      status: null,
      error: null,
      csv: []
    }
  },
  methods: {
    add () {
      this.$store
        .dispatch('add', {
          name: this.name,
          email: this.email,
          phone: this.phone,
          last_name: this.last_name,
          category: this.category
        })
        .then((/* { data } */) => { 
          this.$router.push({ path: "../contacts" })
        })
        .catch(err => {
           this.status = err.response.status;
           this.error = err.response.data.message;
        })
    }
  }
}
</script>