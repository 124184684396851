<template>
  
    <div class="d-flex justify-space-between">
    <v-btn variant="outlined" to="events/create">{{ $t("create") }}</v-btn>
  </div>
  
    
  <div v-if="events !== 'Loading events...'">
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>

</template>

<script>
import EventCard from "../components/EventCard";
import { mapState } from 'vuex'

export default {
  components: { EventCard },
  title: 'Eventi - Events',
  data() {
    return { 
      status: null 
    };
  },
  computed: mapState({
    events: state => state.events
  }),
  created() {
    this.$store
      .dispatch("fetchEvents")
      .catch((err) => {
        this.status = err.response.status;
      });
    this.$store
      .dispatch("fetchContacts")
      .catch((err) => {
        this.status = err.response.status;
      });
  },
};
</script>
