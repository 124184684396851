import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    user: null,
    isNewUser: true,
    events: [],
    event: {},
    contacts: [],
    contact: {}
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      localStorage.setItem("user", JSON.stringify(userData));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.access}`;
      state.user = userData;
    },
    LOGOUT() {
      localStorage.removeItem("user");
      location.reload();
    },
    IS_NEW_USER(state, isNewUser) {
      state.isNewUser = isNewUser;
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_EVENT(state, event) {
      state.event = event
    },
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts
    },
    SET_CONTACT(state, contact) {
      state.contact = contact
    },
  },
  actions: {
    register(_, credentials) {
      return axios
        .post("https://api.eventi.one/api/sign_up/", credentials);
    },
    login({ commit }, credentials) {
      return axios
        .post("https://api.eventi.one/api/log_in/", credentials)
        .then(({ data }) => {
          commit("SET_USER_DATA", data);
        });
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
    isNewUser({ commit }, isNewUser) {
      commit("IS_NEW_USER", isNewUser);
    },
    fetchEvents({ commit }) {
      return axios
        .get('https://api.eventi.one/api/events/')
        .then(({ data }) => {
          commit('SET_EVENTS', data)
        })
    },
    fetchEvent({ commit }, id) {
      return axios
        .get('https://api.eventi.one/api/events/' + id)
        .then(({ data }) => {
          commit('SET_EVENT', data)
        })
    },
    create(_, event) {
      return axios
        .post('https://api.eventi.one/api/events/', event)
    },
    editEvent(_, event) {
      return axios
        .put('https://api.eventi.one/api/events/' + event.id + '/', {name: event.name, address: event.address, venue: event.venue, category: event.category})
    },
    delete(_, eventId) {
      return axios
        .delete('https://api.eventi.one/api/events/' + eventId)
    },
    fetchContacts({ commit }) {
      return axios
        .get('https://api.eventi.one/api/contacts/')
        .then(({ data }) => {
          commit('SET_CONTACTS', data)
        })
    },
    fetchContact({ commit }, id) {
      return axios
        .get('https://api.eventi.one/api/contacts/' + id)
        .then(({ data }) => {
          commit('SET_CONTACT', data)
        })
    },
    add(_, contact) {
      return axios
        .post('https://api.eventi.one/api/contacts/', contact)
    },
    bulk(_, contacts) {
      return axios
        .post('https://api.eventi.one/api/contacts/bulk_create/', contacts)
    },
    editContact(_, contact) {
      return axios
        .put('https://api.eventi.one/api/contacts/' + contact.id + '/', {name: contact.name, email: contact.email, phone: contact.phone, last_name: contact.last_name, category: contact.category})
    },
    remove(_, contactId) {
      return axios
        .delete('https://api.eventi.one/api/contacts/' + contactId)
    },
    invite(_, invitation) {
      return axios
        .post('https://api.eventi.one/api/events/' + invitation.eventId + '/invite/', {guest: invitation.guest, invite_note: invitation.invite_note})
    },
    uninvite(_, inviteId) {
      return axios
        .delete('https://api.eventi.one/api/invitations/' + inviteId)
    },
    react(_, invitation) {
      return axios
        .get('https://api.eventi.one/api/invitations/' + invitation.id + '/' + invitation.action + '/')
    },
    resend(_, inviteId) {
      return axios
        .get('https://api.eventi.one/api/invitations/' + inviteId + '/send_invitation_email/')
    },
    confirm(_, eventId) {
      return axios
        .post('https://api.eventi.one/api/events/' + eventId + '/confirm/')
    },
    start(_, eventId) {
      return axios
        .post('https://api.eventi.one/api/events/' + eventId + '/start/')
    },
  },
});
